import { EnumAppSettings } from 'constants/Enums/app-settings';
import { useStore } from 'zustand-lib/storeGlobal';

const DEFAULT_PRODUCTS_DISPLAYED = 10;

export const useGetProductsDisplayedFromAppSettings = (key: string) => {
  const appSetting = useStore((state: any) => state.appSetting);
  const productsDisplayed = appSetting[EnumAppSettings.PRODUCTS_DISPLAYED] || null;
  return productsDisplayed?.[key] || DEFAULT_PRODUCTS_DISPLAYED;
};
