import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles, styled } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { tooltipClasses } from '@mui/material/Tooltip';
import { getData } from 'clients/Clients';
import { getTotalProductCategories } from 'clients/WebServiceClient';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import { getLinkProxyFile } from 'utils/ImageUtils';
import gtag from 'utils/gtag';
import styles from './styles.module.css';

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    right: -32,
    top: 0,
    overflowY: 'hidden',
    padding: '20px 5px 20px 20px',
    maxWidth: 'fit-content',
    maxHeight: '100%',
    marginTop: 0,
  },
});

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#A2A0A0',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#A2A0A0',
    color: '#fff',
    fontSize: 12,
    top: '5px',
  },
}));

const DrugItem = ({ item, handleClick, index }) => {
  const onClickItem = (e) => {
    handleClick(e);
    gtag.clickDrugGroup(item.name);
  };
  return (
    <ButtonBase
      data-index={index}
      onClick={() => onClickItem(item)}
      className={clsx(
        styles.drugContainer,
        index > 11 && styles.hiddenM,
        index > 9 && styles.hiddenS,
        index > 7 && styles.hiddenXs,
        index > 5 && styles.hiddenXxs,
      )}
    >
      <ImageFallbackStatic src={getLinkProxyFile(item?.icon)} width={70} height={70} alt="icon" />
      <div className={styles.wrapperInfo}>
        <LightTooltip title={item?.name} arrow placement="top">
          <Typography className={styles.name}>{item?.name}</Typography>
        </LightTooltip>
        {item.isLoading ? (
          <Skeleton variant="rect" animation="wave" />
        ) : (
          <Typography className={styles.totalProduct}>{item?.total || 0} sản phẩm</Typography>
        )}
      </div>
    </ButtonBase>
  );
};
const SvgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M16.006 11.0818C16.5418 11.5767 16.5418 12.4233 16.006 12.9182L11.5982 16.99C10.7976 17.7295 9.5 17.1617 9.5 16.0718V7.92819C9.5 6.83827 10.7976 6.27044 11.5982 7.01L16.006 11.0818Z"
      fill="#0E1983"
    />
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#0E1983" />
  </svg>
);
const SvgClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M9.40912 7.99954L13.7052 3.71279C13.8934 3.52463 13.9991 3.26943 13.9991 3.00333C13.9991 2.73723 13.8934 2.48203 13.7052 2.29387C13.5171 2.10571 13.262 2 12.9959 2C12.7298 2 12.4747 2.10571 12.2865 2.29387L8.00039 6.59061L3.71425 2.29387C3.52612 2.10571 3.27095 2 3.00489 2C2.73883 2 2.48367 2.10571 2.29553 2.29387C2.1074 2.48203 2.00171 2.73723 2.00171 3.00333C2.00171 3.26943 2.1074 3.52463 2.29553 3.71279L6.59166 7.99954L2.29553 12.2863C2.20189 12.3792 2.12756 12.4897 2.07684 12.6115C2.02611 12.7332 2 12.8638 2 12.9957C2 13.1277 2.02611 13.2583 2.07684 13.38C2.12756 13.5018 2.20189 13.6123 2.29553 13.7052C2.38841 13.7989 2.49891 13.8732 2.62066 13.9239C2.74241 13.9747 2.873 14.0008 3.00489 14.0008C3.13679 14.0008 3.26737 13.9747 3.38912 13.9239C3.51087 13.8732 3.62137 13.7989 3.71425 13.7052L8.00039 9.40847L12.2865 13.7052C12.3794 13.7989 12.4899 13.8732 12.6117 13.9239C12.7334 13.9747 12.864 14.0008 12.9959 14.0008C13.1278 14.0008 13.2584 13.9747 13.3801 13.9239C13.5019 13.8732 13.6124 13.7989 13.7052 13.7052C13.7989 13.6123 13.8732 13.5018 13.9239 13.38C13.9747 13.2583 14.0008 13.1277 14.0008 12.9957C14.0008 12.8638 13.9747 12.7332 13.9239 12.6115C13.8732 12.4897 13.7989 12.3792 13.7052 12.2863L9.40912 7.99954Z"
      fill="#C0C0C0"
    />
  </svg>
);
const DrugGroup = ({ categories }) => {
  const { data = [] } = categories;
  const classes = useStyles();
  const router = useRouter();
  const [groupDrug, setGroupDrug] = useState([]);
  const [groupDrugDialog, setGroupDrugDialog] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      const initialCategories = data.map((category) => ({ ...category, total: 0, isLoading: true }));
      setGroupDrug(initialCategories.slice(0, 14));
      setGroupDrugDialog(initialCategories);
      const categoriesCode = data.map((item) => item?.code);

      const fetchData = async () => {
        try {
          const response = await getTotalProductCategories({ body: JSON.stringify({ categories: categoriesCode }) });
          const dataRs = getData(response);

          const updatedCategories = initialCategories.map((category) => {
            const dataR = dataRs.find((item) => item.categoryCode === category.code);
            return {
              ...category,
              total: dataR ? dataR.total : category.total,
              isLoading: false,
            };
          });
          setGroupDrug(updatedCategories.slice(0, 14));
          setGroupDrugDialog(updatedCategories);
        } catch (error) {
          console.error('Error fetching API:', error);
        }
      };

      fetchData();
    }
  }, [data]);

  const onClickItem = (item) => {
    const query = JSON.stringify({
      categoryFilters: [{ code: item?.code, categoryCodes: [item?.code], efficacyCodes: [], sellerCategoryCodes: [] }],
    });
    router.push({
      ...router,
      pathname: '/products',
      query: {
        filter: query,
        page: 1,
      },
    });
  };
  return (
    <div className={styles.flexWrapper}>
      <div id="list__drug" className={styles.borderRadius}>
        <div className={styles.parentContainer}>
          <Typography className={styles.title}>
            Nhóm thuốc{' '}
            <ButtonBase className={styles.viewAll} onClick={() => setOpenModal(true)}>
              <span>Xem tất cả</span> <SvgIcon />
            </ButtonBase>
          </Typography>
          {/* <div className={styles.flexItem}> */}
          <div className={styles.drugWrapper}>
            {groupDrug?.map((item, index) => (
              <DrugItem key={item?.code} item={item} handleClick={onClickItem} index={index} />
            ))}
          </div>
          {/* </div> */}
        </div>
      </div>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        classes={{
          paper: classes.dialog,
        }}
      >
        <>
          <div className={styles.flexTitle}>
            <Typography className={styles.title}>Nhóm thuốc</Typography>
            <ButtonBase onClick={() => setOpenModal(false)} className={styles.fixedIcon}>
              <Typography className={styles.close}>
                <span>Đóng</span> <SvgClose />
              </Typography>
            </ButtonBase>
          </div>
          <div className={styles.drugWrapperModal}>
            {groupDrugDialog?.map((item) => (
              <DrugItem key={item?.code} item={item} handleClick={onClickItem} />
            ))}
          </div>
        </>
      </Dialog>
    </div>
  );
};

export default DrugGroup;

