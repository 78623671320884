// import * as Sentry from '@sentry/nextjs';
// TODO: refactor contanst
import { GET } from 'clients/Clients';
import LoadingScreen from 'components/organisms/LoadingScreen';
import { HTTP_REQUEST_PRIORITY } from 'constants/Enums/http';
import { useEffect } from 'react';
import NotifyUtils from 'utils/NotifyUtils';
import { removeSessionToken } from 'utils/SessionUtils';

export const withLogin =
  (Component, checkLogin = true, redirect = {}) =>
  ({ err, ...props }) => {
    // if (maintain) {
    //   return <MaintainPage />;
    // }
    // nếu có lỗi server side thì sẽ in ở đây
    if (err) {
      console.error(err);
    }
    const { url, message } = redirect;
    const { isAuthenticated, isInvalidToken, user, prefetchUrls = [] } = props;
    let msg = '';
    if (checkLogin && !isAuthenticated) {
      msg = message && message.length > 0 ? message : 'Bạn cần đăng nhập để vào được trang này ';
    } else if (isInvalidToken) {
      // server side
      // when user click other page , do with server side run -> check inValidToken
      msg = message && message.length > 0 ? message : 'Phiên làm việc của bạn đã hết hạn, vui lòng đăng nhập lại để có thể tiếp tục thao tác ';
    }

    if (msg && msg.length > 0) {
      removeSessionToken();
      NotifyUtils.error(msg);
      const currentUrl = window.location.pathname;
      setTimeout(() => {
        window.location.replace(url && url.length > 0 ? url : `/?login=true&redirectUrl=${currentUrl}`);
      }, 2000);

      return <LoadingScreen />;
    }

    // prefetch page
    useEffect(() => {
      const controller = new AbortController();
      try {
        const { signal } = controller;
        prefetchUrls?.forEach((URL) => {
          GET({ url: URL, page: true, signal, priority: HTTP_REQUEST_PRIORITY.low, isAuth: isAuthenticated });
        });
      } catch (error) {
        console.error(error);
      }

      return () => controller.abort();
    }, [isAuthenticated]);

    // // init sentry
    // if (user?.customerID) {
    //   Sentry?.setUser({ id: user?.customerID });
    // }

    return <Component {...props} />;
  };

