import { useEffect, useState } from 'react';

const useOpenOnDateChange = (isRequiredCertificates, isAuthenticated) => {
  const [open, setOpen] = useState(null);
  const [isShowNextPopup, setIsShowNextPopup] = useState(false);

  useEffect(() => {
    if (isAuthenticated && isRequiredCertificates) {
      const now = new Date();
      const todayString = now.toISOString().split('T')[0];
      const lastOpenRemindDate = localStorage?.getItem('lastOpenRemindDate');
      if (todayString !== lastOpenRemindDate) {
        setOpen(true);
        localStorage.setItem('lastOpenRemindDate', todayString);
        localStorage.setItem('keyToggleOcrPopup', 'false');
      } else {
        setIsShowNextPopup(true);
      }
    } else {
      setIsShowNextPopup(true);
    }
  }, [isRequiredCertificates, isAuthenticated]);

  useEffect(() => {
    if (open === false) {
      setIsShowNextPopup(true);
    }
  }, [open]);

  return [open, () => setOpen(false), isShowNextPopup];
};

export default useOpenOnDateChange;
