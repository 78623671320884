import Box from '@material-ui/core/Box';
import React from 'react';

export type ListProductForPCWrapperProps = {
  children: React.ReactNode;
  gap?: string;
};

export const ListProductForPCWrapper = ({ children, gap = '20px' }: ListProductForPCWrapperProps) => {
  return (
    <Box display={'grid'} gridTemplateColumns={'repeat(auto-fill, minmax(200px, 1fr))'} gridGap={gap} justifyContent={'center'}>
      {children}
    </Box>
  );
};
