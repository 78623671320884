export enum EnumBlockCodeTracking {
	HOME_MEGA_BLOCK = 'home_mega_block:<blockName>',
	HOME_KE_DON_GIA_TOT = 'home_ke_don_gia_tot',
	HOME_NEW_PRODUCTS = 'home_new-products',
	HOME_BEST_SELLER_PRODUCTS = 'home_best_seller_products',
	HOME_PROMOTION_PRODUCTS = 'home_promotions_products',
	HOME_JFY = 'home_jfy',
	HOME_RCM = 'home_recommendation_products',
	PRODUCT_LIST = 'product-list',
	PRODUCT_LIST_DEFAULT_LIST = 'product-list_default-list',
	PRODUCT_LIST_SEARCH_RESULT = 'product-list_search-result',
	PRODUCT_LIST_SEARCH_NO_RESULT_RCM = 'product-list_search-no-result-rcm',
	PRODUCT_CAMPAIGN_NAME = 'promotion',
	QUICK_ORDER_DEFAULT_LIST = 'quick-order_default-list',
	QUICK_ORDER_SEARCH_RESULT = 'quick-order_search-result',
	QUICK_ORDER_SEARCH_NO_RESULT_RCM = 'quick-order_search-no-result-rcm',
	SELLER_PAGE = 'seller-page',
	WISHLIST = 'wishlist',
	VIEWED_PAGE = 'viewed-page',
	JFY_PRODUCT_LIST = 'just-for-you',
	CART_SELLER_BLOCK = 'cart-page_seller:<sellerCode>',
	CART_NON_PRODUCTS_RCM = 'cart_non-products-rcm',
	PRODUCT_DETAIL_RCM = 'product-detail_rcm',
	PRODUCT_DETAIL_SAME_SELLER = 'product-detail_same-seller',
	PRODUCT_DETAIL_SAME_INGREDIENT = 'product-detail_same-ingredient',
	PRODUCT_DETAIL_SAME_PRODUCT_ID = 'product-detail_same-productId',
	PRODUCT_DETAIL_COMBO_SUGGESTION = 'product-detail_combo-suggestion',
}

export enum EnumTrackingStatic {
	DAILY_DEAL = 'DAILY',
	MEGA_BLOCK = 'mega-block'
}
