import Typography from '@material-ui/core/Typography';
import { getData } from 'clients/Clients';
import { getCampaignListV2 } from 'clients/MarketingClient';
import clsx from 'clsx';
import { useAuth } from 'context/Auth';
import useOnScreen from 'hooks/useOnScreen';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { ImageFallbackBanner, ImageFallbackStatic } from 'utils/ImageFallback';
import { getLinkProxyCDN } from 'utils/ImageUtils';
import { debounceFunc300 } from 'utils/debounce';
import gtag from 'utils/gtag';
import { useStore } from 'zustand-lib/storeGlobal';
import styles from './styles.module.css';

const Event = ({ data }) => {
  const router = useRouter();

  const MyButton = React.forwardRef(({ onClick, href }, ref) => {
    return (
      <a href={href} onClick={onClick} ref={ref} className={clsx(styles.eventContainer)}>
        <Typography className={styles.titleEvent}>{data?.typeLabel || ''}</Typography>
        <div className={styles.wrapperIcon}>
          <Image objectFit="contain" width="100%" src={getLinkProxyCDN(data?.icon)} alt="icon voucher" height="32" />
        </div>
        <div className={styles.wrapperViewMore}>
          {data?.subTitle && <span className={styles.wrapperSubTitle}>{data?.subTitle}</span>}
          <span className={styles.titleNanoBanner}>{data?.title}</span>
          <Typography className={styles.viewMore}> {data?.cta || 'Xem thêm'}</Typography>
        </div>
      </a>
    );
  });

  const handleClick = (e, item) => {
    e.preventDefault();
    gtag.clickCampaignbox(item.title, item.url);
    router.push(item.url);
  };

  return (
    <Link href={data?.url} passHref legacyBehavior prefetch={false}>
      <MyButton onClick={(e) => handleClick(e, data)} />
    </Link>
  );
};

const NanoBanner = ({ dataShowBoxYir }) => {
  const router = useRouter();
  const bannerNano = useStore((state) => state.bannerNano);
  const { user } = useAuth();
  const [listCampaign, setListCampaign] = useState([]);

  useEffect(async () => {
    if (user?.customerID) {
      const fetchData = async () => {
        const res = await getCampaignListV2({
          q: JSON.stringify({
            platform: 'WEB',
          }),
          provinceCode: user.provinceCode,
          limit: 20,
        });

        const campaigns = getData(res);
        let newList = [];
        if (campaigns.length > 3) {
          if (dataShowBoxYir && dataShowBoxYir.campaignBoxId) {
            newList = campaigns.filter((x) => x.campaignBoxID !== dataShowBoxYir.campaignBoxId);
          } else {
            newList = campaigns.slice(0, 3);
          }
        } else {
          newList = campaigns;
          if (dataShowBoxYir && dataShowBoxYir.campaignBoxId) {
            newList = campaigns.filter((x) => x.campaignBoxID !== dataShowBoxYir.campaignBoxId);
          }
        }
        setListCampaign(newList);
      };
      await fetchData();
    }
  }, [user?.customerID, dataShowBoxYir]);

  const ImageBanner = React.forwardRef(({ onClick, href }, ref) => {
    return (
      <a href={href} onClick={onClick} ref={ref}>
        <ImageFallbackBanner src={bannerNano[0]?.imgURL} width={1200} objectFit="cover" layout="fill" />
      </a>
    );
  });

  const handleClickBannerNano = (e, item) => {
    e.preventDefault();
    gtag.clickNanobanner(item?.name, item?.targetURL);
    router.push(item?.targetURL);
  };

  const nanoRefs = useRef();
  const visible = useOnScreen(nanoRefs);
  useEffect(() => {
    if (visible) {
      debounceFunc300(() => {
        gtag.showNanobanner(bannerNano[0]);
        listCampaign?.forEach((item) => {
          gtag.showCampaignbox(item);
        });
      });
    }
  }, [visible]);

  return (
    <div className={styles.containerBanner} ref={nanoRefs}>
      <div className={styles.containerImage}>
        {bannerNano[0]?.imgURL ? (
          <div>
            <Link href={bannerNano[0]?.targetURL || '#'} passHref prefetch={false}>
              <ImageBanner onClick={(e) => handleClickBannerNano(e, bannerNano[0])} />
            </Link>
          </div>
        ) : (
          <ImageFallbackStatic
            src={`${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/nanoBanner.png`}
            width={1200}
            objectFit="cover"
            layout="fill"
          />
        )}
      </div>
      <div className={clsx(styles.rightContainer, listCampaign?.length < 3 && styles.rightContainerSliderLessThen)}>
        {listCampaign?.map((x) => (
          <div key={x.campaignBoxID}>
            <Event data={x} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NanoBanner;

