import Modal from 'components/atoms/Modal';
import React from 'react';
import styles from './styles.module.css';

export default function ModalLayout({ width, onClose, visible, height, children, ...props }) {
  const handleClose = (_, reason) => {
    const modalCloseKeys = ['backdropClick', 'escapeKeyDown'];
    if (!modalCloseKeys.includes(reason)) {
      onClose();
    }
  };
  return (
    <Modal open={!!visible} onClose={handleClose} {...props}>
      <div className={styles.auth_modal_content} style={{ width: `${width}`, height: `${height}` }}>
        <div className={styles.auth_modal_body}>{children}</div>
      </div>
    </Modal>
  );
}
