import ProductCardLite from 'components-v2/mocules/ProductCardLite';
import ListProductWithTitle from 'components/mocules/ListProductWithTitle';
import { EnumProductsDisplayedMobile } from 'constants/Enums/app-settings';
import { useGetProductsDisplayedFromAppSettings } from 'hooks/useGetProductsDisplayedFromAppSettings';
import styles from './styles.module.css';

export default function HomePageBlockV2({
  type = '',
  redirectUrl = '',
  viewMore = true,
  viewMoreInTitle,
  name = '',
  icon,
  product,
  gap,
  forceShowButtonAtBottom,
  blockCodeTracking = '',
  backgroundImage,
}) {
  // String 'HOME_CHEAPEST_PRODUCTS' from Web app Config => EnumAppSettings.PRODUCTS_DISPLAYED
  const MAXIMUM_PRODUCT_SHOW = useGetProductsDisplayedFromAppSettings(EnumProductsDisplayedMobile.HOME_CHEAPEST_PRODUCTS);

  return (
    <div className={styles.containerPage}>
      <ListProductWithTitle
        name={name}
        viewMore={viewMore}
        viewMoreInTitle={viewMoreInTitle}
        redirect={redirectUrl}
        productsType={type}
        icon={icon}
        gap={gap}
        forceShowButtonAtBottom={forceShowButtonAtBottom}
        backgroundImage={backgroundImage}
      >
        {product?.slice(0, MAXIMUM_PRODUCT_SHOW)?.map((p) => (
          <ProductCardLite
            product={{
              ...p,
              blockCode: blockCodeTracking,
            }}
            key={`item-product-${p.slug}`}
          />
        ))}
      </ListProductWithTitle>
    </div>
  );
}
