import { useEffect, useState } from 'react';
import { useProvinces } from './useProvinces';

const LEVEL_OBJECT = {
  LEVEL_SILVER: 'Silver',
  LEVEL_GOLD: 'Gold',
  LEVEL_DIAMOND: 'Diamond',
  LEVEL_PLATINUM: 'Platinum',
};

const useInsiderPopup = (user, insiderUrl, projectId) => {
  const [displayPopup, setDisplayPopup] = useState(false);
  const [isShowNextPopup, setIsShowNextPopup] = useState(false);
  const { getProvinceByCode } = useProvinces();

  useEffect(() => {
    const fetchData = async () => {
      const province = await getProvinceByCode(user?.provinceCode);

      const respFetchScript = await fetch(insiderUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uniqueID: user?.customerID?.toString(), // customerID of customer,
          projectID: +projectId, // projectID of current thuocsivn customer, it's different for each env
          phoneNumber: user?.phone, // phone number of customer,
          name: user?.name,
          email: user?.email,
          customAttributes: [
            {
              key: 'thanh_pho',
              value: province?.name || '',
            },
            {
              key: 'rank',
              value: LEVEL_OBJECT[user?.level],
            },
          ],
          platform: 'WEB',
        }),
      });
      if (respFetchScript?.status === 200) {
        const divInsider = document.createElement('div');
        divInsider.id = 'buymed-popup';
        document.body.appendChild(divInsider);

        const scriptText = await respFetchScript.text();
        const url = URL.createObjectURL(new Blob([scriptText]));
        const scriptEl = document.createElement('script');
        scriptEl.src = url;
        document.body.appendChild(scriptEl);

        setTimeout(() => {
          // Check if divInsider has child elements
          const divInsider = document.getElementById('buymed-popup');
          if (!divInsider || !divInsider.hasChildNodes()) {
            setIsShowNextPopup(true);
          }
        }, 100);
      }
    };
    if (user && displayPopup) {
      fetchData();
    }
  }, [user, insiderUrl, projectId, displayPopup]);

  return { setDisplayPopup, isShowNextPopup };
};

export default useInsiderPopup;

