import { withLogin } from 'HOC';
import { DEFAULT_THUOCSI_LONG_TITLE } from 'constants/data';
import dynamic from 'next/dynamic';
import { doWithServerSide } from 'services/SsrService';
import { IS_UI } from 'sysconfig';

// Must use direct import
// When using dynamic import, when redirect from /ingredients to /home, the CSS for Template is removed, causing missing CSS issue.
// Ref: https://github.com/vercel/next.js/issues/17464
import HomeUser from 'components-v2/organisms/pages/home/HomeUser';
const HomeGuest = dynamic(() => import('components-v2/organisms/pages/home/HomeGuest'), { ssr: false });

const pageName = 'home';
const pageTitle = 'Trang chủ';
const bannerStatus = 'ON';

export async function getServerSideProps(ctx) {
  return doWithServerSide(ctx, async () => {
    return {
      props: {
        SEO_CONFIG: {
          title: DEFAULT_THUOCSI_LONG_TITLE,
        },
        insiderUrl: process.env.URL_INSIDER_POPUP,
        projectId: process.env.PROJECT_ID,
      },
    };
  }, {
	initZustand: ['miniBanners', 'bannerNano', 'banners']
  });
}

function HomePage(props) {
  return IS_UI && !props.isAuthenticated ? (
    <HomeGuest pageName={pageName} pageTitle={pageTitle} showTopSearchMV2 bannerStatus={bannerStatus} />
  ) : (
    <HomeUser {...props} pageName={pageName} pageTitle={pageTitle} bannerStatus={bannerStatus} />
  );
}

export default withLogin(HomePage, false);
