import { GET, POST } from './Clients';

const VIDEO_APIS_PREFIX = '/integration/video/v1';

const getCurrentLiveStream = () => {
  return GET({
    url: `${VIDEO_APIS_PREFIX}/live/marketing/on-air?version=v2`,
  });
};

const getViewCurrentLive = (body) => {
  return POST({
    url: `${VIDEO_APIS_PREFIX}/live/v2/marketing/current-view`,
    body,
  });
};

const getBytePlusToken = (body) => {
  return POST({
    url: `${VIDEO_APIS_PREFIX}/live/token`,
    body,
  });
};

const reportView = (body) => {
  return POST({
    url: `${VIDEO_APIS_PREFIX}/live/v2/marketing/view`,
    body,
  });
};

export default {
  getCurrentLiveStream,
  getBytePlusToken,
  getViewCurrentLive,
  reportView,
};

