import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import useOnScreen from 'hooks/useOnScreen';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { memo, useLayoutEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ImageFallbackBanner } from 'utils/ImageFallback';
import gtag from 'utils/gtag';
import { useStore } from 'zustand-lib/storeGlobal';
import styles from './styles.module.css';

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} role="button">
      <NavigateNextIcon />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} role="button">
      <NavigateBeforeIcon />
    </div>
  );
}

// in-use
const BannerSliderNew = () => {
  const settingsSliderBanner = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    rows: 1,
    swipeToSlide: true,
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dotsClass: styles.dots,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const banners = useStore((state) => state.banners);
  const [infoBanner, setInfoBanner] = useState([]);

  useLayoutEffect(() => {
    setInfoBanner(banners);
  }, [banners]);

  const ref = useRef({});
  const wrapperRef = useRef();
  const visible = useOnScreen(wrapperRef);
  const router = useRouter();
  const viewedBannersRef = useRef({});
  const handleBannerView = (index, banner) => {
    gtag.showBannerNewUI(banner[index]);
    viewedBannersRef.current[index] = true;
  };

  const handleClickBanner = (e, item) => {
    e.preventDefault();
    gtag.clickBannerNewUI(item);
    router.push(item?.targetURL);
  };

  const ImageBanner = React.forwardRef(({ onClick, data, href }, ref) => {
    return (
      <a href={href} onClick={onClick} ref={ref}>
        <ImageFallbackBanner src={data?.imgURL} fallbackSrc={data?.imgUR} width={1200} height={345} objectFit="cover" layout="fill" />
      </a>
    );
  });

  return (
    <div className={clsx(styles.boxSlider)} ref={wrapperRef}>
      <Slider
        ref={ref}
        {...settingsSliderBanner}
        afterChange={(index) => {
          if (visible) {
            handleBannerView(index, infoBanner);
          }
        }}
      >
        {infoBanner.map((data) => (
          <div className={clsx(styles.gridImagesWrapper)} key={data?.imgURL}>
            <div className={styles.gridImages}>
              <div>
                <Link href={data?.targetURL || '#'} passHref prefetch={false}>
                  <ImageBanner data={data} href={data?.targetURL} onClick={(e) => handleClickBanner(e, data)} />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default memo(BannerSliderNew);

