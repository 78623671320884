import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './index.module.css';

interface ConfirmStepProps {
  children: ReactNode;
  classes: string;
  onNext: () => void;
  onClose?: () => void;
  buttonNext: string;
  buttonClose?: string;
}

const ConfirmUI = ({ children, classes, onNext, onClose, buttonNext, buttonClose }: ConfirmStepProps) => {
  return (
    <FormControl className={clsx(styles.ConfirmUI, classes)}>
      {children}
      <FormControl fullWidth>
        {buttonNext && (
          <ButtonBase className={styles.accountCreationForm_button} onClick={onNext}>
            {buttonNext}
          </ButtonBase>
        )}
        {buttonClose && (
          <Button variant="outlined" type="submit" className={styles.btnOutlined} onClick={onClose}>
            {buttonClose}
          </Button>
        )}
      </FormControl>
    </FormControl>
  );
};

export default ConfirmUI;
